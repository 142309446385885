.#{$widget_prefix} {
  &__headline {
    &__container {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 30px 0 55px 0;
      font-weight: 300;
    }

    &__location {
      font-size: 22px;
      font-weight: 700;
      letter-spacing: 2.2px;
      line-height: 30px;
      color: #707070;
      margin-bottom: 8px;
      text-align: center;
      
    }

    &__timestamp, &__voteCountPercentage {
      font-size: 12px;
      letter-spacing: 1.2px;
      line-height: 18px;
      color: #707070;
      text-align: center;
    }
  }
}
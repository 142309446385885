.#{$widget_prefix} {
  &__bar {
    &__percentage {
      &__value {
        fill: #707070;
        font-family: 'Source Sans Pro', sans-serif;
        font-size: 15px;
        line-height: 18px;
      }

      &__topParty {
        font-weight: 700;
      }

      &__change {
        fill: rgba(112, 112, 112, 0.5);
        font-size: 12px;
        line-height: 17px;
      }
    }
    &__directionArrow {
      fill: rgba(112, 112, 112, 0.6);
      font-size: 20px;
      line-height: 27px;
      letter-spacing: 6.9px;
      font-weight: 800;
    }

  }
}
$button-margin-right: 0px;
$button-width: 80px;
$grid-box-width: 90px;

.#{$widget_prefix} {
  &__gallery {
    &__container {
      display: flex;
      flex-direction: row;
      justify-content: center;
      margin-bottom: 20px;

      button {
        background-color: transparent;
        border: none;
        outline: none;
        padding: 0px;
        margin: 0px;
        cursor: pointer;
        // margin-right: $button-margin-right;
        margin-top: 25px;
        display: flex;
        flex-direction: column;
        align-items: center;
        min-width: $button-width;
      }
    }

    &__wrapper {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: flex-end;
    }

    &__grid {
      &__box {
        width: $grid-box-width;
        display: flex;
        justify-content: center;
      }
    }
    &__item {
      &__party {
        // margin-right: 30px;
        // margin-right: $button-margin-right;
        margin-top: 25px;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: $button-width;

        &__circle {
          background-color: #707070;
          border-radius: 50%;
          width: 50px;
          height: 50px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-bottom: 28px;
        }
        &__code {
          font-weight: 700;
          font-size: 20px;
          color: #FFFFFF;
          text-align: center;
          line-height: 27px;

          &__andre {
            font-size: 14px;
          }
        }
      }

      &__district {
        // margin-right: 30px;
        // margin-right: $button-margin-right;
        margin-top: 25px;
        display: flex;
        flex-direction: column;
        align-items: center;
        min-width: $button-width;
        
        &__circle {
          background-color: #707070;
          border-radius: 50%;
          width: 50px;
          height: 50px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        &__code {
          font-weight: 700;
          font-size: 20px;
          color: #FFFFFF;
          line-height: 27px;
          text-align: center;
          text-transform: uppercase;
        }

        &__name {
          color: #707070;
          font-size: 12px;
          font-weight: 700;
          letter-spacing: 1.2px;
          line-height: 17px;
          text-transform: uppercase;
          margin-top: 11px;
          text-align: center;
        }
      }

      &__candidate {
        position: relative;
        &__wrapper {
          position: relative;
        }
        &__image {
          background-color: #e2e2e2;
          width: 66px;
          height: 66px;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          // align-items: flex-end;

          img {
            max-width: 100%;
            border-radius: 50%;
          }
        }
        &__name {
          font-size: 14px;
          line-height: 19px;
          color: #5C5C5C;
          font-weight: 700;
          width: $button-width;
          text-overflow: ellipsis;
          white-space: nowrap;
          display: block;
          overflow: hidden;
        }
        &__status {
          width: 32px;
          height: 32px;
          background-color: #07dd9b;
          border-radius: 50%;
          font-weight: 700;
          font-size: 16px;
          color: #FFFFFF;
          display: flex;
          justify-content: center;
          align-items: center;
          position: absolute;
          top: -2px;
          right: 5px;
        }

        &__party {
          position: absolute;
          top: 40px;
          left: 2px;
          &__circle {
            display: flex;
            flex-direction: row;
            justify-content: center;
            border-radius: 50%;
            background-color: #00974C;
            align-items: center;
            position: relative;
            // box-shadow: 1px 0px 0px #d81926;
            width: 23px;
            height: 23px;
            // width: 100%;
            // height: 0;
            // padding-top: 100%;

            &__r {
              background-color: #800000;
            }
            &__sv {
              background-color: #D41921;
            }
            &__ap {
              background-color: #DA5E74;
              // box-shadow: -1px 0px 0px 0px #d81926;
            }
            &__sp {
              background-color: #00974C;
              // box-shadow: -1px 0px 0px 0px #d81926;
            }
            &__mdg {
              background-color: #67FF7E;
            }
            &__v {
              background-color: #92B34D;
            }
            &__krf {
              background-color: #FFF967;
              // box-shadow: -1px 0px 0px 0px #d81926;
            }
            &__h {
              background-color: #67BFFF;
              // box-shadow: -1px 0px 0px 0px #d81926;
            }
            &__frp {
              background-color: #005E93;
            }
            &__a {
              background-color: #707070;
            }
          }
          // &__code__hidden {
          //   font-size: 12px;
          //   line-height: 17px;
          //   font-weight: 700;
          //   color: #FFFFFF;
          //   visibility: hidden;
          // }

          &__code {
            font-size: 12px;
            line-height: 17px;
            font-weight: 700;
            text-transform: uppercase;
            color: #FFFFFF;
            position: absolute;
            top: 0px;
            left: 0px;
            right: 0px;
            bottom: 0px;
            display: flex;
            justify-content: center;
            align-items: center;
            
            &__mdg, &__frp, &__krf {
              font-size: 8px;
            }

            &__r {
              color: #FFFFFF;
            }
            &__sv {
              color: #FFFFFF;
            }
            &__ap {
              color: #FFFFFF;
            }
            &__sp {
              color: #FFFFFF;
            }
            &__mdg {
              color: #000000;
            }
            &__v {
              color: #FFFFFF;
            }
            &__krf {
              color: #000000;
            }
            &__h {
              color: #FFFFFF;
            }
            &__frp {
              color: #FFFFFF;
            }
          }
        }
      }
    }
  }
}
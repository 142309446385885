.#{$widget_prefix} {
  &__historical-data {
    margin-top: 30px;
    margin-bottom: 60px;

    &__container {
      display: flex;
      justify-content: center;
      color: #707070;

      &__inner {
        &__header {
          font-size: 16px;
          font-weight: 700;
          text-align: center;
          letter-spacing: 5px;
          margin-bottom: 20px;
        }

        &__select-parties {
          display: flex;
          align-items: center;
          justify-content: center;

          cursor: pointer;
          background: none;
          border: none;
          margin: 0 auto 10px auto;

          &__icon {
            margin-right: 12px;
          }

          &__text {
            color: #707070;
            font-size: 12px;
            letter-spacing: 3px;
          }

        }
        &__graph {}
        &__disclaimer {
          margin-top: 30px;
          max-width: 580px;
          font-size: 15px;

          @media screen and (max-width: 779px) {
            font-size: 11px;
          }

          @media screen and (max-width: 759px) {
            padding: 0 15px;
          }
        }
      }
    }

    &__parties {
      position: fixed;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background-color: rgba(255, 255, 255, 0.25);
      backdrop-filter: blur(30px);
      -webkit-backdrop-filter: blur(30px);

      &__container {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;

        &__inner {
          color: #707070;
          font-family: "Open Sans", sans-serif;

          &__items {
            &__item {
              margin-bottom: 16px;
              display: flex;
              align-items: center;

              input[type=checkbox] {
                accent-color: #707070;
                cursor: pointer;
              }

              label {
                cursor: pointer;
                font-size: 12px;
                letter-spacing: 3px;
                padding-left: 10px;
              }
            }
          }

          &__close {
            color: #707070;
            background: none;
            border: none;
            display: block;
            margin: 0 auto 0 auto;
            padding: 0;
            cursor: pointer;
          }
        }
      }
    }
  }
}
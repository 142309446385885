.#{$widget_prefix} {
  &__groupMandates {
    &__container {
      // display: flex;
      // justify-content: center;
      max-width: 735px;
      margin: 30px auto 30px auto;
    }

    &__blocksContainer {
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 10px;
      align-items: flex-end;
    }

    &__blockCount {
      font-size: 40px;
      // line-height: 55px;
      line-height: 32px;
      font-weight: 700;
      // margin-right: 10px;

      // &:last-child {
      //   margin-left: 10px;
      //   margin-right: 0px;
      // }
    }

    &__blockBarsContainer {
      flex: auto;
    }

    &__title {
      text-align: center;
      color: #707070;
      font-family: 'Source Sans Pro', sans-serif;
      font-weight: 700;
      letter-spacing: 0.32px;
      font-size: 18px;
      text-transform: uppercase;
    }

    &__blockBars {
      flex: auto;
      display: flex;
      align-items: flex-end;
      margin: 0px 10px 0px 10px;
      position: relative;

      &__block {
        height: 19px;
        width: 50%;
      }
      &__block.#{$widget_prefix}__groupOne {
        background-color: #FF6767;
      }
      &__block.#{$widget_prefix}__groupTwo {
        background-color: #67BFFF;
      }
    }

    &__winningLine {
      position: absolute;
      width: 1.9px;
      background-color: #35CBB9;
      height: 42px;
      left: 50%;
      top: -11px;
      // transform: translate(-1px, 0);
    }

    &__winningText {
      // position: absolute;
      // left: 50%;
      // top: -30px;
      margin-bottom: 12px;
      margin-top: 12px;
      text-align: center;

      color: #35CBB9;
      font-family: 'Source Sans Pro', sans-serif;
      font-size: 12px;
      line-height: 15px;
      font-style: italic;
      text-transform: uppercase;
    }

    &__blocksLabelContainer {
      display: flex;
      justify-content: space-between;
      max-width: 625px;
      margin: 0 auto;
    }

    &__blockLabel {
      color: #707070;
      font-size: 18px;
      line-height: 24px;
      font-weight: 300;
      text-transform: uppercase;
    }
  }
}
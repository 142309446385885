$basePath: '2023';

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap');
@import '~@csstools/normalize.css';



// @font-face {
//   font-family: 'TradeGothicBoldCondensedN20';
//   src: url('./../fonts/TradeGothicW02-BoldCn20.eot'); /* IE9 Compat Modes */
//   src: url('./../fonts/TradeGothicW02-BoldCn20.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
//        url('./../fonts/TradeGothicW02-BoldCn20.woff2') format('woff2'), /* Super Modern Browsers */
//        url('./../fonts/TradeGothicW02-BoldCn20.woff') format('woff'), /* Pretty Modern Browsers */
//        url('./../fonts/TradeGothicW02-BoldCn20.ttf')  format('truetype'), /* Safari, Android, iOS */
//        url('./../fonts/TradeGothicW02-BoldCn20.svg#svgFontName') format('svg'); /* Legacy iOS */
// }
// @font-face {
//   font-family: 'GillSansInfantRegular';
//   src: url('./../fonts/GillSansInfantW01.eot'); /* IE9 Compat Modes */
//   src: url('./../fonts/GillSansInfantW01.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
//        url('./../fonts/GillSansInfantW01.woff2') format('woff2'), /* Super Modern Browsers */
//        url('./../fonts/GillSansInfantW01.woff') format('woff'), /* Pretty Modern Browsers */
//        url('./../fonts/GillSansInfantW01.ttf')  format('truetype'), /* Safari, Android, iOS */
//        url('./../fonts/GillSansInfantW01.svg#svgFontName') format('svg'); /* Legacy iOS */
// }
// @font-face {
//   font-family: 'GillSansInfantBold';
//   src: url('./../fonts/GillSansInfantW01-Bold.eot'); /* IE9 Compat Modes */
//   src: url('./../fonts/GillSansInfantW01-Bold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
//        url('./../fonts/GillSansInfantW01-Bold.woff2') format('woff2'), /* Super Modern Browsers */
//        url('./../fonts/GillSansInfantW01-Bold.woff') format('woff'), /* Pretty Modern Browsers */
//        url('./../fonts/GillSansInfantW01-Bold.ttf')  format('truetype'), /* Safari, Android, iOS */
//        url('./../fonts/GillSansInfantW01-Bold.svg#svgFontName') format('svg'); /* Legacy iOS */
// }

body {
  margin: 0;
}


.ntbValgResult {
  @import "common";
}

// .ntbValgResult[data-popper-interactive='false'] {
//   pointer-events: none;
// }

.ntbValgResult.tooltip-top-container {
  z-index: 4000;
  font-family: "Open Sans", sans-serif;
}




.#{$widget_prefix} {
  &__container {
    padding-top: 42px;
    padding-left: 30px;
    padding-right: 30px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    position: relative;
  }

  &__tooltip {
    position: absolute;
    z-index: 1070;
    display: block;
    margin: 0;
    font-family: "Open Sans", sans-serif;
    font-size: 15px;
    color: #707070;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    word-spacing: normal;
    white-space: normal;
    line-break: auto;
    word-wrap: break-word;
    opacity: 0;
    pointer-events: none;
  }
  
  &__displayFlex {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  &__ticker {
    margin-top: 30px;

    &__contentContainer {
      color: #707070;
      line-height: 22px;
      max-width: 700px;
      margin: 0 auto;
    }

    h2, &__title {
      font-weight: 700;
      font-size: 24px;
      line-height: 35px;
      margin: 0 0 25px 0;
    }

    h4, &__byline {
      font-weight: 700;
      font-size: 18px;
      margin: 0 0 25px 0;
    }

    p {
      font-weight: 400;
      font-size: 16px;
      margin: 0 0 25px 0;
    }
  }

  &__loading {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    z-index: 1000;
    background: rgba(255, 255, 255, 0.6);
  }

  &__loading__inline {
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.6);
  }

  &__show-more {
    margin-bottom: 80px;

    &__container {
      display: flex;
      justify-content: center;

      &__button {
        cursor: pointer;
        padding: 0;
        margin: 0;
        background: none;
        border: none;
        color: #e0e0e0;
      }
    }
  }

  &__test-data-warning {
    display: flex;
    justify-content: center;

    &__text {
      font-family: "Open Sans", sans-serif;
      font-size: 15px;
      color: #AF0000;
      text-align: center;
      max-width: 360px;
      margin-top: 35px;
    }
  }
}

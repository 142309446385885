// .#{$widget_prefix} {
//   .rc-tabs-nav-list {

//   }
// }

.rc-tabs {
  border: none;
}

.rc-tabs-nav-list {
  width: 100%;
  justify-content: space-around;
}

.rc-tabs-tab {
  flex: 1;
  justify-content: center;
  border-bottom: #e0e0e0 4px solid;
  padding-bottom: 6px;
  font-weight: 400;
  background: transparent;

  &.rc-tabs-tab-disabled {
    cursor: default;

    .rc-tabs-tab-btn {
      color: rgba(82, 78, 78, 0.2);
    }
  }

  &:not(.rc-tabs-tab-disabled):hover:not(.rc-tabs-tab-active):hover {
    .rc-tabs-tab-btn {
      color: rgb(112, 112, 112, 1);
    }
  }

  &.rc-tabs-tab-active .rc-tabs-tab-btn {
    font-weight: 700;
    color: #35cbb9;
  }
}

.rc-tabs-tab-btn {
  font-size: 18px;
  letter-spacing: 1.8px;
  line-height: 24px;
  color: rgb(112, 112, 112, 0.39);
  text-transform: uppercase;
  outline: none;
}

.rc-tabs-ink-bar {
  background: #35cbb9;
}

.rc-tabs-top .rc-tabs-ink-bar,
.rc-tabs-bottom .rc-tabs-ink-bar {
  height: 4px;
}

.rc-tabs-nav-operations {
  display: none;
}

.#{$widget_prefix} {
  &__newParliament {
    &__tabs {
      &__container {
        display: flex;
        justify-content: center;

        .rc-tabs {
          overflow: visible;
        }
        .rc-tabs-nav-wrap {
          overflow: visible;
        }
        .rc-tabs-nav-list {
          margin-bottom: 30px;
        }
        .rc-tabs-tab {
          min-width: 161px;

          // &.rc-tabs-tab-active {
          //   position: relative;
          //   &::after {
          //     content: '>';
          //     display: block;
          //     font-size: 20px;
          //     font-weight: 800;
          //     letter-spacing: 6.9px;
          //     line-height: 27px;
          //     transform: rotate(90deg);
          //     position: absolute;
          //     bottom: -35px;
          //     color: #35CBB9;
          //   }
          // }
        }

        .rc-tabs.rc-tabs-small-272 {
          .rc-tabs-tab {
            min-width: 130px;
          }
        }

        .rc-tabs-ink-bar-animated {
          display: flex;
          flex-direction: row;
          justify-content: center;

          &::after {
            content: ">";
            display: block;
            font-size: 20px;
            font-weight: 800;
            letter-spacing: 6.9px;
            line-height: 27px;
            transform: rotate(90deg);
            position: absolute;
            bottom: -35px;
            color: #35cbb9;
          }
        }
      }
    }
  }
}

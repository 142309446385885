@import 'charts/mandates';
@import 'charts/stacked-bar';
@import 'charts/bar';
@import 'charts/group-bar';
@import 'charts/pie';
@import 'charts/line';

.#{$widget_prefix} {
  &__charts {
    &__column {
      margin: 0 30px;
      display: flex;
      flex-direction: column;
    }

    &__headerTitle {
      color: #707070;
      font-size: 15px;
      font-weight: 400;
      line-height: 23px;
      text-align: center;
      max-width: 215px;
      margin: 40px auto 0 auto;

      &--no-mt {
        margin-top: 0;
      }
    }

    &__label {
      color: #35CBB9;
      font-weight: 700;
      font-size: 15px;
      letter-spacing: 1.5px;
      line-height: 20px;
      text-align: center;
      display: block;
      margin-top: auto;

      &::before {
        content: '>';
        font-size: 20px;
        font-weight: 800;
        letter-spacing: 6.9px;
        line-height: 27px;
        margin-top: -10px;
        transform: rotate(-90deg);
        display: block;
      }

      &::after {
        content: '';
        border-bottom: 2px solid #35CBB9;
        width: 94px;
        margin: 7px auto 0 auto;
        display: block;
      }
    }
  }
}

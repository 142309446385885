.#{$widget_prefix} {
  &__pie {
    &__labelText {
      line-height: 1em;
      fill: #707070;
    }

    &__partyTitle {
      //
    }

    &__candidateCount {
      font-weight: 700;
    }

    &__mandateChange {
      font-weight: normal;
      font-style: italic;
      fill: #707070;
    }

    &__bottomInfotext {
      text-transform: uppercase;
    }
  }
}
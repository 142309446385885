.#{$widget_prefix} {
  &__mandates {
    display: flex;

    // todo: make some javascript to automatically set font-size based on width?
    font-size: 55px; // 100px - can be adjusted to adjust size
    width: 335px; // can be adjusted to adjust size

    &__container {
      position: relative;
      width: 100%;
      margin: 0 auto;

      display: flex;
      justify-content: center;

      &__chart {
        margin: 1em 0;
        width: 70%;

        .mandates_svg__st0 {
          //cursor: pointer;
          transition: all 200ms ease-in-out;
          fill: #dedede;
        }
      }

      &__parties {
        position: absolute;
        height: 100%;
        width: 100%;

        &__entry {
          position: absolute;
          cursor: pointer;

          transition: opacity 200ms ease-in-out;
          opacity: 0;
          pointer-events: none;

          &--active {
            opacity: 1;
            pointer-events: all;
          }

          &__header {
            display: flex;
            align-items: center;

            color: #707070;
            padding-bottom: 0.06em;
            border-bottom: 1px solid;

            &__text {
              font-size: 0.22em;
              font-weight: lighter;
            }

            &__amount {
              font-weight: bold;
              font-size: 0.24em;
              margin-left: 0.5em;
            }
          }

          &__change {
            text-align: right;
            font-size: 0.19em;
            padding-top: 0.5em;
            color: #707070;
          }
        }
      }

      &__center {
        position: absolute;
        color: #707070;

        height: 100%;
        width: 100%;

        display: flex;
        justify-content: center;
        align-items: center;

        transition: opacity 200ms ease-in-out;
        opacity: 0;

        &--active {
          opacity: 1;
        }

        &__inner {
          text-align: center;
          margin-top: 1.3em;

          &__header {
            font-size: 0.24em;
            font-weight: bold;
            padding-bottom: 0.4em;
          }

          &__text {
            font-size: 0.22em;
            font-weight: lighter;
          }
        }
      }
    }
  }
}

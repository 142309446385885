@function str-replace($string, $search, $replace: '') {
  $index: str-index($string, $search);

  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
  }

  @return $string;
}


.#{$widget_prefix} {
  &__search {
    &__buttonContainer {
      display: flex;
      justify-content: center;
    }

    @at-root body.#{$widget_prefix}__search__ReactModal__Body--open {
      overflow: hidden;
    }

    &__icon {
      background-image: url(~assets/images/iconSearch.svg);
      background-repeat: no-repeat;
      background-position: center;
      display: inline-block;
      width: 20px;
      height: 25px;
      margin-right: 20px;
      flex-shrink: 0;

      &--tighter {
        margin-right: 12px;
      }

      @media screen and (max-width: 466px) {
        margin-right: 10px;
      }
    }

    &__button {
      line-height: 20px;
      letter-spacing: 1.5px;
      color: #707070;
      font-size: 15px;
      cursor: pointer;
      display: flex;
      align-items: center;
      background: #F7F7F7;
      padding: 8px 55px 8px 18px;
      border-radius: 21px;
      border: none;
      outline: none;
      margin: 0;
      white-space: nowrap;

      @media screen and (max-width: 466px) {
        padding: 8px 30px 8px 18px;
      }

      &::before {
        display: none;
      }
    }

    &__buttonClose {
      margin: 2px 0 0 0;
      background: none;
      box-shadow: none;
      border: 0;
      color: #707070;
      padding: 0 8px 0 10px;
      cursor: pointer;
      font-size: 20px;
      outline: none;

      &::before {
        display: none;
      }
    }

    &__formContainer {
      display: flex;
      // justify-content: center;
      // margin: 0 auto;
      max-width: 500px;
      align-items: center;

    }

    &__input {
      color: #707070;
      height: 20px;
      line-height: 20px;
      font-size: 15px;
      font-weight: 400;
      border: none;
      outline: none;
      min-width: 240px;
      padding: 1px 2px;
      background: transparent;

      &::placeholder {
        opacity: 1;
        background: transparent;
      }
    }

    &__loader__container {
      display: flex;
      margin-left: 40px;
    }

    &__result {
      &__container {
        &__border {
          padding-top: 31px;
          margin-left: 15px;
          border-bottom: 1px solid #707070;
          margin-bottom: 30px;
        }
      }

      &__listGroup {
        margin: 0 0 0 35px;
        padding: 0;
        list-style: none;

        li {
          display: block;

          button {
            display: block;
            background: none;
            border: none;
            outline: 0px;
            padding: 0px;
            margin: 0px 0px 0px 0px;
            // margin-top: 10px;
            font-family: 'Open Sans', sans-serif;
            font-size: 15px;
            font-weight: 400;
            color: #707070;
            line-height: 30px;
            cursor: pointer;
            text-align: left;
            letter-spacing: 0px;
            position: relative;
            text-transform: none;
            &::before {
              display: none;
            }

            &:hover, &.emphasized, &.selected {
              color: #35CBB9;
              // font-weight: 700;
              // text-shadow: 0 0 1px;
              // text-stroke: 1px #35CBB9;
              // -webkit-text-stroke: 1px #35CBB9;
              // text-shadow: 0 0 1px #35CBB9, 0 0 1px #35CBB9, 0 0 1px #35CBB9;

              //span:nth-child(2) {
              //  font-weight:bold
              //}
            }

            span:nth-child(1) {
              visibility: hidden;
              white-space: normal;
              font-weight: bold;
            }

            span:nth-child(2) {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              white-space: normal;
              width: 100%;

              // &:hover {
              //   font-weight:bold
              // }
            }

            // &::before {
            //   display: block;
            //   content: attr(title);
            //   font-weight: bold;
            //   height: 0;
            //   overflow: hidden;
            //   visibility: hidden;
            // }
          }
        }
      }

      // &__listItem {

      // }
    }

    &__modal {
      &__overlay {
        position: fixed;
        top: 0px;
        right: 0px;
        bottom: 0px;
        left: 0px;
        background-color: rgba(255, 255, 255, 0.25);
        backdrop-filter: blur(30px);
        -webkit-backdrop-filter: blur(30px);
        overflow-y: scroll;
        display: flex;
        justify-content: center;
        z-index: 1000;
      }

      &__content {

        $mcontent: &;
        $selectorWithoutWrapper: str-replace(#{&}, '.ntbValgResult', '');


        font-family: 'Open Sans', sans-serif;
        position: relative;
        top: 0px;
        right: 0px;
        bottom: 0px;
        left: 0px;
        border: none;
        background: none;
        // overflow: auto;
        border-radius: 4px;
        outline: none;
        padding: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;

        &__container {
          transform: translate(0, -50px);
          transition: opacity .3s linear, transform .3s ease-out;
          padding: 10px 20px;
          opacity: 0;
        }

        &.ReactModal__Content--after-open {
          #{$selectorWithoutWrapper}__container {
            transform: none;
            opacity: 1;
          }
        }

        &.ReactModal__Content--before-close {
          #{$selectorWithoutWrapper}__container {
            transform: translate(0, -50px);
            opacity: 0;
          }
        }
      }

    }
  }
}
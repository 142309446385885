.#{$widget_prefix} {
  &__modal {
    &__loader {
      padding-top: 40px;
    }

    &__candidate {
      &__wrapper {
        width: 280px;
        padding: 10px;
        position: relative;
      }
      &__container {
        border: 4px solid #dcdbdc;
        padding: 20px;
        min-height: 150px;
        background-color: #FFFFFF;
        // transition: all 0.7s;
      }
      &__content {
        display: flex;
        flex-direction: column;
        align-items: center;
        max-height: 550px;
        overflow-y: auto;
      }
      &__title {
        font-size: 18px;
        line-height: 21px;
        color: #707070;
        padding-bottom: 20px;
        font-weight: 400;
        margin: 0px;
        text-align: center;
        max-width: 200px;
      }
      &__situation {
        color: #35CBB9;
        font-weight: 800;
        font-size: 14px;
        line-height: 19px;
        padding-bottom: 5px;
        margin: 0px;
        text-align: center;
      }
      &__picture {
        padding-bottom: 15px;
        position: relative;
        width: 105px;
        height: 105px;
        border-radius: 50%;

        img {
          max-width: 100%;
          border-radius: 50%;
        }
      }
      &__party__circle {
        width: 25px;
        height: 25px;
        border-radius: 50%;
        font-size:10px;
        font-weight: 700;
        color: #FFFFFF;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        bottom: 12px;
        left: 15px;

        &--r {
          background-color: #800000;
        }
        &--sv {
          background-color: #D41921;
        }
        &--ap {
          background-color: #DA5E74;
        }
        &--sp {
          background-color: #00974C;
        }
        &--mdg {
          background-color: #67FF7E;
          color: #000000;
        }
        &--v {
          background-color: #92B34D;
        }
        &--krf {
          background-color: #FFF967;
          color: #000000;
        }
        &--h {
          background-color: #02b3ff;
        }
        &--frp {
          background-color: #005E93;
        }
        &--a {
          background-color: #707070;
        }
      }

      &__bio__location {
        padding-bottom: 25px;
        max-width: 210px;
      }
      &__bio, &__place__location {
        font-size: 14px;
        line-height: 22px;
        color: #5C5C5C;
        text-align: center;
      }
      &__bio {
        max-width: 160px;
        margin: 0 auto;
      }
      &__name {
        font-weight: 700;
      }
      &__age, &__place__location {
        font-weight: 300;
      }
      &__age {
        display: block;
      }
      &__oath {
        font-size: 16px;
        line-height: 22px;
        letter-spacing: 0.08px;
        color: #000000;
        font-weight: 700;
        padding-bottom: 15px;
        width: 100%;
      }
      &__commitment {
        font-size: 14px;
        line-height: 19px;
        letter-spacing: 0.01px;
        color: #000000;
        width: 100%;
      }
    }
    &__close__button {
      position: absolute;
      bottom: 0px;
      right: 2px;
      // background-image: url(~assets/images/close-button.svg);
      width: 40px;
      height: 40px;
      border: none;
      outline: none;
      background-color: transparent;
      cursor: pointer;
    }
  }
}
.#{$widget_prefix} {
  &__notifications {
    &__testData {
      &__container {
        position: absolute;
        top: 0px;
        background-color: #707070;
        left: 50%;
        transform: translate(-50%, 0px);
        max-width: 714px;
        width: 100%;
        height: 26px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        letter-spacing: 1.2px;
        line-height: 17px;
        color: #FFFFFF;
      }
      
    }

    &__resultPublishTime {
      font-size: 14px;
      color: #707070;
      text-align: center;
      padding-top: 20px;
      font-weight: 400;
      text-transform: uppercase;
    }
  }
  
}